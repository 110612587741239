(function($){

    $(function(){

        $(document).ready(function() {
            $('.main-image .inner').sliderPro({
                width: 940,
                height: 260,
                responsive : true,
                arrows: true,
                fade: true,
                slideAnimationDuration : 5000,
                touchSwipe : false,
                breakpoints: {
                    940: {
                        width: '100%',
                        visibleSize: 'auto',
                        buttons: true,
                        autoHeight: true,
                        touchSwipe : true,
                    }
                }
            });

            var $slider = $('.main-image .inner').data( 'sliderPro' );
            $( '.sp-slide' ).on('click', function(){
                $(':input[name="search_word"]', 'form[name="led_temp"]').val('');
                $('input[name="led_shape[]"][value="cob"]', 'form[name="led_temp"]').prop('checked', 0);
                var index = $slider.getSelectedSlide();
                switch (index) {
                    case 0 :
                        window.open().location.href = 'https://shinkoh-elecs.mrc-a.com/newproduct/optisolis/';
                        return false;
                        break;
                    case 1 :
                        $('input[name="search_word"]', 'form[name="led_temp"]').val('757H');
                        break;
                    case 2 :
                        $('input[name="search_word"]', 'form[name="led_temp"]').val('19F');
                        break;
                    case 3 :
                        $('input[name="led_shape[]"][value="cob"]', 'form[name="led_temp"]').prop('checked', 1);
                        $('input[name="search_word"]', 'form[name="led_temp"]').val('V4');
                        break;
                    case 4 :
                        window.open().location.href = 'https://shinkoh-elecs.mrc-a.com/ncsu334b-slide/';
                        return false;
                        break;
                }
                $('.search-button').click();
            });
        });
    });

})(jQuery);