(function($){

    $(function(){
        $('table.uv tr').each(function(i){
            $(this).find('td,th').each(function(i){
                $(this).addClass('column'+i);
            });
            if ($(this).find('.column7').find('div').length) {
                var set_height = $(this).find('.column7').find('div').height();
                $(this).find('.column5').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                $(this).find('.column6').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                $(this).find('.column8').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                $(this).find('.column9').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
            }
        });
        $.favorite.load();
    });

})(jQuery);
