// Google Maps API
const Gmap = {
    set : function(_zoom, _lat, _lng, _target) {
        var myOptions = {
            zoom : _zoom,
            center : new google.maps.LatLng(_lat, _lng),
            mapTypeId : google.maps.MapTypeId.ROADMAP
        }
        return new google.maps.Map(document.getElementById(_target), myOptions);
    },
    marker : function(p, _map) {
        return new google.maps.Marker({
            position : new google.maps.LatLng(p.lat, p.lng),
            map : _map
        });
    },
    info : function(p){
        var offset = new google.maps.Size(0, -32);
        return new google.maps.InfoWindow({
            position : new google.maps.LatLng(p.lat, p.lng),
            content : p.address,
            pixelOffset : offset
        });
    },
    setManyPins : function(d, map) {
        var self = this;
        $.each(d.places, function(k, v) {
            var marker = self.marker(v, map);
            var infowindow = self.info(v);
            google.maps.event.addListener(marker, 'click', function(){
                infowindow.open(map);
            });
        });
    },
    setZoom : function(d) {
        var map = this.set(d.zoom, d.lat, d.lng, d.target);
        var marker = this.marker(d, map);
        var infowindow = this.info(d);
        
        infowindow.open(map);
        
        google.maps.event.addListener(marker, 'click', function(){
            Gmap.setZoom(d);
        });
        
    },
    draw : function(d) {
        var map = this.set(d.init_zoom, d.centerLat, d.centerLng, d.target);
        this.setManyPins(d, map);
    }
};