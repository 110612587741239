import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import './utils/jquery.easing.1.3';
import './utils/jquery.dataTables.min';
import './utils/jquery.sliderPro.min';
import './utils/jquery.cookie';

import './utils/bigSlide';
import './utils/bigSlideSettings';
import './utils/main';
import './utils/gmap';
import './utils/led_search';
import './utils/led_favorite';
import './utils/led_histories';
import './utils/led_inquiry';

import './utils/slider';
import './utils/led';
import './utils/uvled';
