(function($){

    $(function(){
        $('.led .product-list-table').dataTable( {
            'pageLength': 100,
            'scrollY': '530px',
            'lengthMenu': [ 10, 25, 50, 100, 250, 500 ],
            'scrollCollapse': true,
            'ordering': false,
            'fnDrawCallback' : function(){
                $('table tr').each(function(i){
                    $(this).find('td,th').each(function(i){
                        $(this).addClass('column'+i)
                    });
                    if ($(this).find('.column4').find('div').length) {
                        var set_height = 42;
                        $(this).find('.column4').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column5').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column6').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column7').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column10').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                    }
                    if ($(this).find('.column8').find('div').length) {
                        var set_height = $(this).find('.column8').find('div').height();
                        $(this).find('.column4').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column5').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column6').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column7').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                        $(this).find('.column10').find('div').css('height', set_height+'px').css('line-height', set_height+'px');
                    }
                });
                $.favorite.load();
            },
            'oTableTools' : {
                'aButtons' : [
                    {
                        'sExtends' : 'text',
                        'fnClick' : function(nButton, oConfig) {
                            this.fnPrint(true, oCnfig);
                        }
                    }
                ]
            }
        });

        // ブラウザ戻るボタンを押して一覧ページに戻ってきた際に表示件数をセットされた値にする
        $(window).on('load', function(){
            $(':input[name="DataTables_Table_0_length"]').val($('.view_no').val()).change();
        });
        $(window).on('unload', function(){
            $(':input[name="DataTables_Table_0_length"]').val($('.view_no').val()).change();
        });

    });

})(jQuery);