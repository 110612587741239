(function($){

$(function(){
    var cookiename = 'shinkoh_histories';
    
    var viewHistories = {}, histories = [],
        ajax_url = '/wp-admin/admin-ajax.php';
    
    $.viewHistories = {
        get : function() {
            var shinkoh_histories = $.cookie(cookiename);
            $('#histories .column-7').remove();
            // COOKIEから閲覧履歴データを取得
            $.ajax({
                'type': 'POST',
                'url' : ajax_url,
                'data' : {'action' : 'histories', 'post__in' : shinkoh_histories},
                'datatype' : 'json',
                'beforeSend' :function(){
                     $('.no-histories').hide();
                     $('#histories .loading').show();
                },
                'success' : function(json){
                    if (typeof json.data !== 'undefined') {
                        if (json.data.length > 0) {
                            if (json.data.length < 3) {
                                $('#histories .swipeable').addClass('swiped');
                            }
                            $('#histories .row').append(json.data.join(''));
                        } else {
                            $('.no-histories').show();
                        }
                    } else {
                        var j = JSON.parse(json);
                        if (j.data.length > 0) {
                            if (j.data.length < 3) {
                                $('#histories .swipeable').addClass('swiped');
                            }
                            $('#histories .row').append(j.data.join(''));
                        } else {
                            $('.no-histories').show();
                        }
                    }


                    $('#histories .loading').hide();
                }
            });
            return false;
        },
        add : function(){
            var shinkoh_histories = $.cookie(cookiename);
            var currentID = $('#histories').attr('data-currentid');
            // COOKIEデータ更新
            if (typeof shinkoh_histories !== 'undefined' ) {
                // 未定義の場合は新規追加
                histories = shinkoh_histories.split(',');
                if ($.inArray(currentID, histories) == -1) {
                    // 今いるページの履歴があるかどうか検索
                    if (histories.length > 6) {
                        // 7件目を削除して先頭に最新履歴を追加
                        histories.pop();
                        histories.unshift(currentID);
                        $.removeCookie(cookiename); 
                        $.cookie(cookiename, histories.join(','), { expires: 180, path: '/' });
                    } else {
                        // 先頭に最新履歴を追加
                        histories.unshift(currentID);
                        $.removeCookie(cookiename); 
                        $.cookie(cookiename, histories.join(','), { expires: 180, path: '/' });
                    }
                    return true;
                } else {
                    // 同じ製品が履歴に存在するので順番のみ入れ替える
                    histories = $.grep(histories, function(a){
                        return a != currentID;
                    });
                    histories.unshift(currentID);
                    $.removeCookie(cookiename); 
                    $.cookie(cookiename, histories.join(','), { expires: 180, path: '/' });
                    return true;
                }
            } else {
                // 新規閲覧履歴追加
                $.removeCookie(cookiename); 
                $.cookie(cookiename, currentID, { expires: 180, path: '/' });
                return true;
            }
        },
        remove : function(id){
            var shinkoh_histories = $.cookie(cookiename);
            histories = shinkoh_histories.split(',');
            histories = $.grep(histories, function(a){
                return a != id;
            });
            $.removeCookie(cookiename); 
            if (histories.length > 0) {
                $.cookie(cookiename, histories.join(','), { expires: 180, path: '/' });
            } else {
                $.cookie(cookiename, null, { expires: -1, path: '/' });
            }
            $.viewHistories.get();
            return id;
        },
        allClear : function(){
            $.cookie(cookiename, null, { expires: -1, path: '/' });
            $.viewHistories.get();
        }
    }
    
    // ロード時
    $.viewHistories.get();
    
    // 個別の履歴削除機能
    $(document).on('click', '#histories .icon-cancel-circle', function(){
        var id = $(this).parents('.column-7').attr('data-productid');
        $.viewHistories.remove(id);
        $(this).parents('.column-7').remove();
        if ($('#histories .column-7').length == 0) {
            $('#histories .no-hisories').show();
        }
        return false;
    });
    
    // 履歴をすべて削除
    $('.allClear').click(function(){
        $.viewHistories.allClear();
    });

});

})(jQuery);