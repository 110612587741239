(function($){

$(function(){

    var topBtn = $('.page-top');
    topBtn.hide();

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });

    $('table tr').each(function(){
        $(this).find('td,th').each(function(k,v){
            $(this).addClass("col-"+k);
        });
    });

    $('.internal-search .icon-search').click(function(){
        if ($(this).prev().val() != '') {
            $(this).closest('.internal-search').submit();
        }
    });

    // Histories swipe
    if ($('#histories').length > 0) {
        let timerIdHistories = setInterval(function(){
            if ( $('#histories .swipeable .column-7').length > 0) {
                if ($('#histories .swipeable .column-7').eq(0).offset().left < 0) {
                    $('#histories .swipeable').addClass('swiped');
                    clearInterval(timerIdHistories);
                }
            }
        }, 3000);
    }
    if ($('#led-product').length > 0) {
        let timerIdProduct1 = setInterval(function () {
            if ($('#led-product .dataTables_scrollBody .product-list-table').offset().left < 0) {
                $('#led-product').addClass('swiped');
                clearInterval(timerIdProduct1);
            }
        }, 3000);
    }

    if ($('#uvled-surface-product').length > 0) {
        let timerIdProduct2 = setInterval(function () {

            if ($('#uvled-surface-product .product-list-table').offset().left < 0) {
                $('#uvled-surface-product').addClass('swiped');
                clearInterval(timerIdProduct2);
            }
        }, 3000);
    }

    if ($('#uvled-lamp-product').length > 0) {
        let timerIdProduct2 = setInterval(function () {

            if ($('#uvled-lamp-product .product-list-table').offset().left < 0) {
                $('#uvled-lamp-product').addClass('swiped');
                clearInterval(timerIdProduct2);
            }
        }, 3000);
    }

    /**
     * anchorScroll
     */

    $.fn.anchorScroll = function(options) {
        var defaults = {
            duaration : 800,
            easing : 'easeInOutCubic',
            padding : 60
        };
        var settings = $.extend(defaults, options);
        var $this = this;

        $($this).bind('click', function(event){
            var $taget = $(this).attr('href');
            if ($taget === '#') {
                $('html,body').stop().animate({scrollTop : 0}, settings.duaration, settings.easing);
            } else {
                $('html,body').stop().animate({scrollTop : $($taget).offset().top - settings.padding}, settings.duaration, settings.easing);
            }
            event.preventDefault();
        });
        return this;
    }
    $('a[href^="#"]').anchorScroll();

});

})(jQuery);
