(function($){

$(function(){

    // 製品お問い合わせ
    var inquiry = {}, inquiries = [],
        ajax_url = '/wp-admin/admin-ajax.php';

    $.inquiry = {
        getUrlVars : function() {
            var vars = [], max = 0, hash = "", array = "";
            var url = window.location.search;
            
            //?を取り除くため、1から始める。複数のクエリ文字列に対応するため、&で区切る
            hash  = url.slice(1).split('&');
            max = hash.length;
            for (var i = 0; i < max; i++) {
                array = hash[i].split('='); //keyと値に分割。
                vars.push(array[0]); //末尾にクエリ文字列のkeyを挿入。
                vars[array[0]] = array[1]; //先ほど確保したkeyに、値を代入。
            }
            return vars;
        },
        get : function(){
            
            var url_vars = $.inquiry.getUrlVars();
            var product_ids = url_vars.product_ids;
            if (typeof product_ids === 'undefined') {
                $('.hide').hide();
            } else {
                product_ids = product_ids.replace(/%2C/g, ',');
            }
            $.ajax({
                'type': 'POST',
                'url' : ajax_url,
                'data' : {'action' : 'inquiry', 'post__in' : product_ids},
                'datatype' : 'json',
                'beforeSend' :function(){
                    $('.inquiry-block .loading').show();
                },
                'success' : function(json){
                    if (typeof json.data !== 'undefined') {
                        if (json.data.length > 0){
                            $('.inquiry-poducts').append(json.data.join(''));
                        }
                    } else {
                        var j = JSON.parse(json);
                        if (j.data.length > 0){
                            $('.inquiry-poducts').append(j.data.join(''));
                        }
                    }
                    $('.inquiry-block .loading').hide();
                }
            });
            return false;
        }
    }
    
    // 一括お問い合わせ
    $('.products-inquiry').click(function(){
        var id_count = $(':input[name="product_select[]"]:checked').length,
            ids = [];
        if (id_count == 0) {
            alert('製品を選択してください');
        } else {
            $(':input[name="product_select[]"]:checked').each(function(){
                ids.push($(this).val());
            });
            $(':input[name="product_ids"]', 'form[name="products-inquiry"]').val(ids.join(','));
            $('form[name="products-inquiry"]').submit();
        }
        return false;
    });
    
});

})(jQuery);