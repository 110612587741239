(function($){

$(function(){
    var cookiename = 'shinkoh_favorite';
    
    // COOKIEからお気に入りデータを取得
    var favorite = {}, favorites = [],
        ajax_url = '/wp-admin/admin-ajax.php';

    $.favorite = {
        load : function(){
            var shinkoh_favorite = $.cookie(cookiename);
            if (typeof shinkoh_favorite !== 'undefined' ) {
                favorites = shinkoh_favorite.split(',');
                for (var i=0; i<favorites.length; i++) {
                    $(':input[name="product_select[]"][value="'+favorites[i]+'"]').parents('tr').
                        find('.icon-star-empty').addClass('icon-star-full').removeClass('icon-star-empty').end().
                            find('div').find('.add-favorite').addClass('remove-favorite').removeClass('add-favorite');
                }
            }
        },
        get : function(){
            var shinkoh_favorite = $.cookie(cookiename);
            $('.favorite-block .top .column-5').remove();
            $.ajax({
                'type': 'POST',
                'url' : ajax_url,
                'data' : {'action' : 'favorite', 'post__in' : shinkoh_favorite},
                'datatype' : 'json',
                'beforeSend' :function(){
                    $('.no-favorite').hide();
                    $('.favorite-block .loading').show();
                },
                'success' : function(json){
                    if (typeof json.data !== 'undefined') {
                        if (json.data.length > 0){
                            $('.favorite-block .top').append(json.data.join(''));
                        } else {
                            $('.no-favorite').show();
                        }
                    } else {
                        var j = JSON.parse(json);
                        if (j.data.length > 0){
                            $('.favorite-block .top').append(j.data.join(''));
                        } else {
                            $('.no-favorite').show();
                        }
                    }
                    $('.favorite-block .loading').hide();
                }
            }); 
            return false;
        },
        add : function(id, refresh){
            var shinkoh_favorite = $.cookie(cookiename);
            // COOKIEデータ更新
            if (typeof shinkoh_favorite !== 'undefined' ) {
                favorites = shinkoh_favorite.split(',');
                // 未定義の場合は新規追加
                if ($.inArray(id, favorites) == -1) {
                    // クリックされたお気に入りがあるかどうかチェック
                    if (favorites.length > 4) {
                        // 5件まで受け付ける
                        alert('お気に入り登録ができるのは5件までです');
                        return false;
                    } else {
                        // 先頭に最新履歴を追加
                        favorites.unshift(id);
                        $.removeCookie(cookiename); 
                        $.cookie(cookiename, favorites.join(','), { expires: 180, path: '/' });
                        if (refresh) {
                            $('.favorite-button').click();
                        }
                        return true;
                    }
                } else {
                    // 同じ製品が存在するのでfalseを返す
                    alert('すでにお気に入りに登録済みの製品があります。');
                    return false;
                }
            } else {
                // 新規お気に入り追加
                $.removeCookie(cookiename); 
                $.cookie(cookiename, id, { expires: 180, path: '/' });
                if (refresh) {
                    $('.favorite-button').click();
                }
                return true;
            }
            return false;
        },
        batchProcess : function(ids){
            var shinkoh_favorite = $.cookie(cookiename);
            var total = ids.length + favorites.length;
            if (typeof shinkoh_favorite !== 'undefined' ) {
                favorites = shinkoh_favorite.split(',');
                if ( total > 5) {
                    alert('お気に入り登録ができるのは5件までです');
                    return false;
                } else {
                    for (var i=0; i < ids.length; i++) {
                        $.favorite.add(ids[i]);
                    }
                    $('.favorite-button').click();
                }
            } else {
                if ( total > 5) {
                    alert('お気に入り登録ができるのは5件までです');
                    return false;
                } else {
                    for (var i=0; i < ids.length; i++) {
                        $.favorite.add(ids[i]);
                    }
                    $('.favorite-button').click();
                }
            }
            return false;
        },
        remove : function(id){
            var shinkoh_favorite = $.cookie(cookiename);
            favorites = shinkoh_favorite.split(',');
            favorites = $.grep(favorites, function(a){
                return a != id;
            });
            $.removeCookie(cookiename); 
            if (favorites.length > 0) {
                $.cookie(cookiename, favorites.join(','), { expires: 180, path: '/' });
            } else {
                $.cookie(cookiename, null, { expires: -1, path: '/' });
            }
            $.favorite.get();
                return favorites;
        }
    }
    
    // お気に入り個別追加
    $('body').on('click', '.add-favorite', function(event){
        event.preventDefault();
        var id = $(this).attr('data-productid');
        if ($.favorite.add(id, true)){
            $(this).find('.icon-star-empty').removeClass('icon-star-empty').addClass('icon-star-full');
            $(this).addClass('remove-favorite').removeClass('add-favorite');
        }
        return false;
    });
    // お気に入り個別削除
    $('body').on('click', '.remove-favorite', function(event){
        event.preventDefault();
        var id = $(this).attr('data-productid');;
        $.favorite.remove(id);
        $(this).find('.icon-star-full').removeClass('icon-star-full').addClass('icon-star-empty');
        $(this).addClass('add-favorite').removeClass('remove-favorite');
        return false;
    });
    // お気に入り削除(Popup内)
    $('body').on('click', '.favorite-block .icon-cancel-circle', function(){
        var id = $(this).parents('.column-5').attr('data-productid');
        $.favorite.remove(id);
        $(':input[name="product_select[]"][value="'+id+'"]').parents('tr').
            find('.icon-star-full').removeClass('icon-star-full').addClass('icon-star-empty');
        $(':input[name="product_select[]"][value="'+id+'"]').parents('tr').
            find('a.remove-favorite').addClass('add-favorite').removeClass('remove-favorite');
        return false;
    });
    
    // 一括登録
    $('.products-favorite').click(function(event){
        event.preventDefault();
        var favorites = $(':input[name="product_select[]"]:checked').length,
            ids = [];
        if (favorites == 0) {
            alert('製品を選択してください');
        } else {
            $(':input[name="product_select[]"]:checked').each(function(){
                ids.push($(this).val());
            });
            $.favorite.batchProcess(ids, false);
        }
        return false;
    });
    
    // お気に入りPopup表示
    $('.favorite-button').click(function(event){
        event.preventDefault();
        if ($('.favorite-block').css('display') == 'none'){
            $('.favorite-block').show().animate({'right': '0px'}, {duration:300, easing: 'easeOutQuart', queue : false});
        }
        $.favorite.get();
        $('body').append('<div class="click-layer">')
        return false;
    });
    // お気に入りPopup非表示
    $('body').on('click', '.click-layer', function(){
        $('.favorite-block').hide().css('right', '-800px');
        $(this).remove();
    });
    
    // お気に入りPopup(比較)
    $('body').on('click', '.favorite-block .compare', function(){
        var param = $(':input', '.favorite-block .column-5').serialize();
        window.open('/led-compare/?'+param, '', 'width=980,height=570,resizable=yes,scrollbars=yes');
    });

    // お気に入りPopup(データシート)
    $('body').on('click', '.favorite-block .datasheet', function(){
        var param = $(':input', '.favorite-block .column-5').serialize();
        window.location.href = '/download-datasheet/?'+param;
    });

    // お気に入りPopup(お問い合わせ)
    $('body').on('click', '.favorite-block .inquiry', function(){
        var ids = [];
        $(':input', '.favorite-block .column-5').each(function(){
            ids.push($(this).val());
        });
        window.location.href = '/contact/?product_ids='+ids.join(',');
    });


});

})(jQuery);