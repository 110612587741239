$(function() {
  $('.sp-menu').bigSlide({
    'menu': ('#sp-nav'),
    'menuWidth': '100%',
    'speed': '500',
    'activeBtn': 'active',
    'side': 'right',
    'beforeOpen': function () {
      beforeOpen();
    },
    'afterOpen': function () {
      afterOpen();
    },
    'beforeClose': function () {
      beforeClose();
    },
    'afterClose': function () {
      afterClose();
    }
  });
  let beforeOpen = function(){

  };
  let afterOpen = function(){

  };
  let beforeClose = function(){

  };
  let afterClose = function(){

  };

});