(function($){

$(function(){

    $('form[name="led_temp"]').submit(function(){
        var formObj =$(this).serializeArray(),
            led_shape_array = [],
            led_color_array = [],
            led_use_array = [],
            led_output_array = [],
            led_rendering_array = [];
        for (var i =0; i < formObj.length; i++){
            if (formObj[i].name == 'led_shape[]') {
                led_shape_array.push(formObj[i].value);
            } else if (formObj[i].name == 'led_color[]') {
                led_color_array.push(formObj[i].value);
            } else if (formObj[i].name == 'led_use[]') {
                led_use_array.push(formObj[i].value);
            } else if (formObj[i].name == 'led_output[]') {
                led_output_array.push(formObj[i].value);
            } else if (formObj[i].name == 'led_rendering[]') {
                led_rendering_array.push(formObj[i].value);
            } else {
                $('form[name="led"] :input[name="'+formObj[i].name+'"]').val(formObj[i].value);
            }
        }
        $('form[name="led"] :input[name="led_shape"]').val(led_shape_array.join(','));
        $('form[name="led"] :input[name="led_color"]').val(led_color_array.join(','));
        $('form[name="led"] :input[name="led_use"]').val(led_use_array.join(','));
        $('form[name="led"] :input[name="led_output"]').val(led_output_array.join(','));
        $('form[name="led"] :input[name="led_rendering"]').val(led_rendering_array.join(','));
        $('form[name="led"] :input').each(function(k,v){
            if(v.value != '') {
                dataLayer.push({
                  'event': 'ledSearch',
                  'eventCategory': 'searchChange',
                  'eventAction': v.name,
                  'eventLabel': v.value
                });
            }
        });
        setTimeout(function(){
            $('form[name="led"]').submit();
        }, 600);
        return false;
    });

    // すべてチェック
    $('.check-all').click(function(){
        if ($(':input[name="product_select[]"]:checked').length > 0) {
            $(this).parents('.inner').find(':input[name="product_select[]"]').prop('checked', false);
            $(this).parents('.inner').find('.check-all').text('すべてチェック');
        } else {
            $(this).parents('.inner').find(':input[name="product_select[]"]').prop('checked', true);
            $(this).parents('.inner').find('.check-all').text('チェックを外す');
        }
        return false;
    });
    $(document).on('change', ':input[name="product_select[]"]', function(){
        if ($(':input[name="product_select[]"]:checked').length > 0) {
            $(this).parents('.inner').find('.check-all').text('チェックを外す');
        } else {
            $(this).parents('.inner').find('.check-all').text('すべてチェック');
        }
    });

    // 製品比較
    $('.products-compare').click(function(){
        var selected = $(':input[name="product_select[]"]:checked').length;
        if (selected > 1 ) {
            window.open('/led-compare/?'+$(':input[name="product_select[]"]:checked').serialize(), '', 'width=1200,height=560,resizable=yes,scrollbars=yes');
        } else {
            alert('製品を２つ以上選択してください');
        }
        return false;
    });

    // 表示件数切替UI
    $(':input[name="view_no"]').change(function(){
        $('.view_no').val($(this).val());
        $(':input[name="DataTables_Table_0_length"]').val($(this).val()).change();
    });

});

})(jQuery);
